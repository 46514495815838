<template></template>

<script>
export default {
  created() {
    $.post(api + "/table/link", {
      id: window.location.href.split("/s/")[1],
    })
      .then(function (r) {
        window.location = r;
      })
      .catch(function () {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
      });
  },
};
</script>

<style>
</style>